<template>
  <section class="sections-video-banner">
    <nuxt-img class="sections-video-banner__bg-image" :src="videoBlock.videoBannerImageUrl" alt="Video banner" height="640" sizes="xs:320 sm:640 md:768 lg:1024 xl:1280 xxl:1920" fit="cover" loading="lazy" />
    <div class="container">
      <div class="__youtube-video-wrapper" :class="{'--playing': isVideoPlaying}">
        <LazyBlocksYoutube :video-url="videoBlock.videoUrl" video-id="whUcnDjbteQ" :auto-play="false" :play-video="isVideoPlaying" hide-play-button :init-iframe="shouldInitVideoIframe" @videoStopped="isVideoPlaying = false" />
        <div class="__video-controls">
          <div class="__control-buttons">
            <div class="__video-close-button-wrapper" @click="isVideoPlaying = false">
              <img v-lazy-load  :data-src="closeButton" class="__video-close-button" alt="Video close button">
            </div>
          </div>
        </div>
      </div>

      <div class="__video-control-box">
        <h2>{{ videoBlock.videoTitle }}</h2>
        <div class="__play-button-wrapper">
          <div class="__play-button" @click="isVideoPlaying = true">
            <img v-lazy-load  :data-src="playButton" alt="Play button" width="22" height="28">
          </div>
          <span class="__play-button-label">Watch Video</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import playButton from '~/assets/images/icons/play.webp';
import closeButton from '~/assets/images/icons/close.webp';
import {Ref} from 'vue';

// DATA Definitions
// ---------------------------------------------------------------------------------------------------------------------
const shouldInitVideoIframe: Ref<boolean> = ref(false);
const isVideoPlaying: Ref<boolean> = ref(false);

const props = defineProps({
  videoBlock: {
    type: Object,
    default: () => {
      return {
        videoUrl: 'https://www.youtube.com/watch?v=1q8Xg0vDZV4',
        videoTitle: 'More homes. That\'s our Mission!',
        videoBannerImageUrl: '/images/backgrounds/video-bg.webp',
      };
    },
  },
});
/**
 * Component MOUNTED!
 */
onMounted(() => {
  document.addEventListener('scroll', setShouldInitVideoIframe);
});

/**
 * Set initVideoIframe to true. This avoids loading the iframe at the page load
 */
function setShouldInitVideoIframe () {
  shouldInitVideoIframe.value = true;
  document.removeEventListener('scroll', setShouldInitVideoIframe);
}
</script>

<style lang="scss">
.sections-video-banner {
  height: 640px;
  position: relative;

  .sections-video-banner__bg-image {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
  }

  .__video-control-box {
    max-width: 100%;
    width: 420px;
    height: fit-content;
    background: rgb(var(--background-light));
    padding: 40px 30px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 60px;
    z-index: 6;
    position: absolute;

    .__play-button-wrapper {
      padding-top: 40px;
      display: inline-flex;
      align-items: center;

      .__play-button {
        position: relative;
        width: 80px;
        height: 80px;
        cursor: pointer;
        background: rgb(var(--accent));
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        transition: all 0.2s ease-in;

        &:before {
          position: absolute;
          content: '';
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 1px solid rgb(var(--accent));
          left: 0;
          right: 0;
          animation: pulse 1.5s infinite ease-in;
        }

        @keyframes pulse {
          0%,
          100% {
            scale: 1;
            opacity: 0.8;
          }

          98% {
            scale: 1.5;
            opacity: 0;
          }
          99% {
            scale: 1;
            opacity: 0;
          }
        }

        &:hover {
          box-shadow: 0 0 10px 3px rgb(0 0 0 / 30%);

          &:before {
            visibility: hidden;
          }

          transition: all 0.2s ease-out;
        }
      }

      .__play-button-label {
        font-size: 14px;
        margin-left: 24px;
        font-weight: 500;
        color: rgb(var(--text-lighter-1));
      }
    }
  }

  .__youtube-video-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
    transition: opacity 0.25s ease-out, z-index 0.1s 1s;

    .__video-controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 20px;

      .__control-buttons {
        position: absolute;
        display: inline-flex;
        justify-content: flex-end;
        right: 0;
        top: 0;
        padding: 8px;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.9);
        scale: 1;
        width: 165px;
        height: 60px;

        .__full-screen-button-wrapper {
          svg {
            width: 45px;
            height: 45px;
            fill: #fff;
          }
        }

        .__video-close-button-wrapper {
          display: flex;
          align-items: center;
          padding-right: 12px;

          img {
            width: 20px;
            height: auto;
          }
        }

        .__full-screen-button-wrapper,
        .__video-close-button-wrapper {
          scale: 1;

          &:hover {
            scale: 1.2;
          }

          transition: scale 0.2s ease-out;
        }
      }
    }

    &.--playing {
      opacity: 1;
      z-index: 10;
      transition: opacity 0.8s ease-in;
    }
  }

  // RESPONSIVENESS
  @media screen and (max-width: $grid-lg) {
    .__video-control-box {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: $grid-sm) {
    .__video-control-box {
      margin-top: -20px;
    }
  }
}
</style>
